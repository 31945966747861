import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import LandingPageTemplate from '../../components/LandingPageTemplate';
import mainImage from '../../images/corona_ueberbrueckungshilfe_foerderung_04.jpg';
import mainImageMobile from '../../images/corona_ueberbrueckungshilfe_foerderung_04_mobile.webp';
import {Link} from 'gatsby';

const context = {
  title: 'Corona Förderung für Umbaumaßnahmen im Hygienekonzept', // Große Haupt-Überschrift
  subTitle: 'Bundesministerium fördert mit Corona Überbrückungshilfe III Plus und Überbrückungshilfe IV bis zu 20.000 Euro im Monat', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Häufig gestellte Fragen zur Förderung von automatischen Schiebetüren und Glasschiebewänden durch Überbrückungshilfe 3 Plus',
  image: mainImage, //Haupt-Bild der Landingpage
  imageMobile: mainImageMobile, //Haupt-Bild der Landingpage
  imageAlt: "Förderung für Umbaumaßnahme zur Umsetzung von Hygienekonzepten durch Coronahilfe des Bundesministerium für Wirtschaft und Energie", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Beratung zu Ihrer Automatiktür",
  configurator: true, // optional, aktiviert den Konfigurator Button wenn 'true'
  configuratorButtonText: "Automatische Schiebetür online konfigurieren", // optional, überschreibt den default Titel
  suggestedProductsTitle: "Passende Automatiktür24 Produkte", // optional, überschreibt den default Titel
  suggestedProducts: [
    '/produkte/automatische-schiebetuer/',
    '/produkte/glasschiebewand/',
    '/produkte/ganzglas-automatikschiebetuer/'
  ],
  text:
  <div>
    <Accordion
      allowZeroExpanded>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Was wird gefördert?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            <i>"Förderfähig sind fortlaufende, im Förderzeitraum Juli 2021 bis Dezember 2021 anfallende vertraglich
              begründete oder behördlich festgesetzte und nicht einseitig veränderbare betriebliche Fixkosten
              gemäß der folgenden Liste [...]"</i>
            <br/><br/>
            Maßnahme Nr. 14 aus der Liste:
            <br/>
            <i>"Bauliche Modernisierungs-, Renovierungs- oder Umbaumaßnahmen bis zu 20.000 Euro pro Monat zur Umsetzung
              von Hygienekonzepten zur Eindämmung des Infektionsgeschehens.. [...]
              z. B. Abtrennungen, Teilung von Räumen, Absperrungen. Umrüstung von Türschließanlagen auf kontaktlos"</i>

            <br/>
            <br/>
            <small>Quelle: <a href="https://www.ueberbrueckungshilfe-unternehmen.de/UBH/Redaktion/DE/FAQ/FAQ-Ueberbruckeungshilfe-III-Plus/ueberbrueckungshilfe-lll-plus.html"
                              rel="nofollow">FAQ www.ueberbrueckungshilfe-unternehmen.de</a> Abschnitt 2.4</small>

          </div>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            In welcher Höhe wird gefördert?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            <i>"Die Überbrückungshilfe III Plus erstattet einen Anteil in Höhe von
              <ul>
                <li>bis zu 100 % der förderfähigen Fixkosten bei Umsatzeinbruch &gt; 70 %</li>
                <li>bis zu 60 % der förderfähigen Fixkosten bei Umsatzeinbruch &ge; 50 % und &le; 70 %</li>
                <li>bis zu 40 % der förderfähigen Fixkosten bei Umsatzeinbruch &ge; 30 % und &lt; 50 %</li>
              </ul>
              im Fördermonat im Vergleich zum entsprechenden Monat des Jahres 2019."</i>

            <br/>
            <br/>
            <small>Quelle: <a href="https://www.ueberbrueckungshilfe-unternehmen.de/UBH/Redaktion/DE/FAQ/FAQ-Ueberbruckeungshilfe-III-Plus/ueberbrueckungshilfe-lll-plus.html"
                              rel="nofollow">FAQ www.ueberbrueckungshilfe-unternehmen.de</a> Abschnitt 2.1</small>

          </div>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Wer wird gefördert?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            <ul className="list-disc list-inside">
              <li>Unternehmen aller Branchen</li>
              <li>max. 750 Mio Eur Umsatz in 2020</li>
              <li>30% Umsatzeinbruch durch Corona im Vergleich zu 2019</li>
            </ul>
            <br/>
            <i>"Grundsätzlich sind Unternehmen [...] bis zu einem weltweiten Umsatz von 750 Mio. Euro im Jahr 2020,
            Soloselbständige und selbständige Angehörige der Freien Berufe im Haupterwerb aller Branchen für
            den Förderzeitraum Juli 2021 bis Dezember 2021 antragsberechtigt, die in einem Monat des Förderzeitraums
            einen Corona-bedingten Umsatzeinbruch von mindestens 30 Prozent im Vergleich zum Referenzmonat
              im Jahr 2019 erlitten haben."</i>
            <br/><br/>
            <small>Quelle: <a href="https://www.ueberbrueckungshilfe-unternehmen.de/UBH/Redaktion/DE/FAQ/FAQ-Ueberbruckeungshilfe-III-Plus/ueberbrueckungshilfe-lll-plus.html"
                              rel="nofollow">FAQ www.ueberbrueckungshilfe-unternehmen.de</a> Abschnitt 1.1</small>
          </div>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Wo und wie kann ich die Förderung beantragen?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            <i>"Der Antrag ist zwingend durch einen prüfenden Dritten im Namen des Antragsstellenden über
              eine digitale Schnittstelle an die Bewilligungsstellen der Länder einzureichen."</i>
            <br/><br/>
            Prüfende Dritte können z.B. Steuerberater, Steuerbevollmächtigte, Wirtschaftsprüfer,
            Buchprüfer oder Rechtsanwälte sein.
            Der Antrag erfolgt dann über den Prüfenden Dritten via <a href="https://antragslogin.ueberbrueckungshilfe-unternehmen.de/">https://antragslogin.ueberbrueckungshilfe-unternehmen.de/</a>
            <br/><br/>
            <small>Quelle: <a href="https://www.ueberbrueckungshilfe-unternehmen.de/UBH/Redaktion/DE/FAQ/FAQ-Ueberbruckeungshilfe-III-Plus/ueberbrueckungshilfe-lll-plus.html"
                              rel="nofollow">FAQ www.ueberbrueckungshilfe-unternehmen.de</a> Abschnitt 3</small>
          </div>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            In welchem Zeitraum wird gefördert?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            <i>"Die Überbrückungshilfe III Plus kann für bis zu sechs Monate (Juli 2021 bis Dezember 2021) beantragt werden. "</i>
            <br/><br/>
            <i>"Berücksichtigungsfähig sind ausschließlich solche Verbindlichkeiten, deren vertragliche Fälligkeit im
              Förderzeitraum liegt (inklusive vertraglich vereinbarte Anzahlungen). Maßgeblich für den Zeitpunkt der
              vertraglichen Fälligkeit ist ausschließlich der Zeitpunkt, der sich nach der (ersten)
              Rechnungsstellung ergibt [...]."</i>
            <br/><br/>
            <i>"Der Antrag kann bis zum 31. Dezember 2021 gestellt werden."</i>
            <br/><br/>
            <small>Quelle: <a href="https://www.ueberbrueckungshilfe-unternehmen.de/UBH/Redaktion/DE/FAQ/FAQ-Ueberbruckeungshilfe-III-Plus/ueberbrueckungshilfe-lll-plus.html"
                              rel="nofollow">FAQ www.ueberbrueckungshilfe-unternehmen.de</a> Abschnitte 2.1, 2.4 und 3.7</small>
            <br/><br/>
            <div className="text-green-500 text-4xl float-left p-3 mr-3 border border-green-500">!</div>
            Sie benötigen ein zeitnahes Angebot und rasche Umsetzung? <br/>
            Jetzt <Link to="/kontakt/">Kontakt</Link> aufnehmen oder direkt <Link to="/automatiktuer-konfigurator/">Online Schiebetür-Konfigurator</Link> nutzen.

          </div>
        </AccordionItemPanel>
      </AccordionItem>

    </Accordion>

    <div className="text-xs text-gray-600 mt-8">
      <p className="font-bold">Disclaimer</p>
      Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und umgesetzt.
      Wir bemühen uns, diese Informationen aktuell, inhaltlich richtig sowie vollständig anzubieten.
      Dennoch ist das Auftreten etwaiger Fehler nicht auszuschließen. Eine Haftung für die Richtigkeit, Vollständigkeit
      und Aktualität dieser Webseiten kann daher trotz sorgfältiger Prüfung nicht übernommen werden.
      Die jeweils aktuell gültige Regelung finden Sie auf den jeweiligen Förderseiten von Bund- und Länder.
      Unser Informationsangebot ersetzt nicht die Fachberatungung durch Ihren Steuerberater oder Rechtsanwalt.
    </div>
  </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
